import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { navigate, Link } from 'gatsby'
import { fechaAtexto } from '../utils/momentUtils'

import { logout } from '../store/actions/useAuth'

import Filtros from './Filtros'
import Perfil from './Perfil'
import { Button, Navbar, Nav, Dropdown, Modal, NavDropdown} from 'react-bootstrap'
import Logo from './Logo'

const Menu = props => {
  const isAuthenticated = useSelector( state => state.auth.loggedIn );
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    props.setHeaderOrange(expanded)
  }, [expanded]);

  return (
    <>
    <Navbar expand="lg" expanded={expanded} onToggle={() => setExpanded(!expanded)}>
      
      {/* <Navbar.Brand as={Link} to={"/app/dashboard/"+fechaAtexto(new Date(), "MMYYYY")}><Logo /></Navbar.Brand> */}
      <Navbar.Brand as={Link} to="/app/dashboard/"><Logo logoWhite={expanded} /></Navbar.Brand>{/* Navbar Brand de logo */}
      
      <Navbar.Toggle aria-controls="basic-navbar-nav" className="d-xs-block d-sm-block d-md-none d-lg-none"><i className="fas fa-bars"></i><i className="fas fa-times"></i></Navbar.Toggle>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          {/* index redirecciona a login o al dashboard en caso de estar logeado */}
          {/* {(isAuthenticated)?
          ""
          :
          <Nav.Link href="/login">Login</Nav.Link>
          } */}
        </Nav>

        {/* Filtros se despliega solo si es una pantalla pequeña */}
        <div className="d-xs-block d-sm-block d-md-none d-lg-none">
          <Filtros setExpanded={setExpanded} locationPath={props.locationPath}/>
          <Perfil edificio={props.data} edificiosUsuario={props.edificiosUsuario}/>
        </div>
        
      </Navbar.Collapse>
      
      <Navbar.Brand className="navbar-right d-none d-md-block"><Perfil edificio={props.data} edificiosUsuario={props.edificiosUsuario} /></Navbar.Brand>{/* Navbar Brand de perfil */}
    </Navbar>
      
    </>
  );
}

export default Menu;