import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from 'react-redux'
import { isMobile } from 'react-device-detect'

import Menu from './Menu'
import Filtros from './Filtros'
import { fechaAtexto, textoAFecha, InicioMesFecha, FinMesFecha, fechaActual } from '../utils/momentUtils'

import { setearEdificio, setearConsejos, setearBibliogafias, setearBase } from '../store/actions/app'
import { informesPeriodoEdificio } from '../store/actions/certificados'

const Header = props => {
  const dispatch = useDispatch();
  const auth = useSelector(state => (state)?state.auth:null);
  const edificio = useSelector(state => (state)?state.app.edificio:null);

  console.log(props)

  const user = auth.user || null
  const edificiosUsuario = user.edificios
  const isAuthenticated = props.isAuthenticated || false
  
  const [isLoading, setIsLoading] = useState(false)
  const [headerOrange, setHeaderOrange] = useState(false)

  const storeInicial = () => {
    if(edificio){
      dispatch( informesPeriodoEdificio(edificio.id) )
      dispatch( setearBase(user.empresa.id) )
    }    
  }

  useEffect(() => {
    setIsLoading(true)
    if (isAuthenticated) {
      storeInicial()
    }
    setIsLoading(false)
  }, [isAuthenticated]);

  return (
    <>
    <header className={`header ${(isMobile)?"mobile":""} ${(headerOrange)?"headerOrange":"headerWhite"}`}>
      <div className="container header-top">
        <Menu data={edificio} edificiosUsuario={edificiosUsuario} locationPath={props.locationPath} setHeaderOrange={setHeaderOrange} />
      </div>

      {/* Filtros se depliegan solo cuando la pantalla es grande */}
      {(isAuthenticated && !isLoading)
        ? <div className="container d-none d-md-block"><Filtros data={edificio} locationPath={props.locationPath} /></div>
        : null
      }
    </header>
    <div className="footer-header text-center col-12 pt-0 pb-2">
      La fecha de hoy es {textoAFecha().format("DD/MM/YYYY")}
    </div>
    </>
  );
}

export default Header;