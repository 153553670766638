import React, { useState } from "react"
import { useDispatch } from 'react-redux'
import { navigate, Link } from 'gatsby'
import { Dropdown, Modal, Navbar, Nav, La } from 'react-bootstrap'
import { logout } from '../store/actions/useAuth'
import * as utils from '../utils/utils'
import { setearEdificio } from '../store/actions/app'

const Perfil = props => {
  const dispatch = useDispatch();
  //Obtenemos la data del edificio desde las props del componente
  const edificio = props.edificio
  const edificiosUsuario = props.edificiosUsuario

  //Métodos para generar el dropdown
  const [value, setValue] = useState('');
  const CustomToggle = React.forwardRef(({ children, onClick, image = "" }, ref) => (
    <div  
    ref={ref} 
    onClick={(e) => { e.preventDefault(); onClick(e); }}
    >
      {children}
    </div>
  ));
  const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value),
            )}
          </ul>
        </div>
      );
    },
  );

  //Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  //Modal edificio
  const [showEst, setShowEst] = useState(false);
  const handleCloseEst = () => setShowEst(false);
  const handleShowEst = () => setShowEst(true);

  const handleLogout = (e) => {
    e.preventDefault()
    dispatch(logout())
    navigate("/login")
  }

  const cambiarEdificio = (edificio) => {
    console.debug("edificio a cambiar: ", edificio)
    utils.setStorage("edificio", JSON.stringify( edificio ) )
    dispatch( setearEdificio( edificio ) )
  }

  return (    
    <>
      {(edificio)?
      <>
      {/* Perfil se despliega solo si es una pantalla grande */}
        <Dropdown className="perfil d-none d-md-block">
          <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
            <div className="d-flex flex-row justify-content-end align-items-center">
              <img className="perfil-foto" src={(edificio.foto) ? process.env.STRAPI_URL + edificio.foto.url : '/img/perfil-default.png'} />
              <div className="perfil-nombre">{edificio.nombre}</div>
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu className="perfil-items" as={CustomMenu}>
            <Dropdown.Item className="perfil-item p-2" eventKey="1" onClick={handleShow}>Perfil establecimiento</Dropdown.Item>
            {/* <Dropdown.Item className="perfil-item p-2" eventKey="2" onClick={handleShowEst}>Cambiar establecimiento</Dropdown.Item> */}
            <Dropdown.Item className="perfil-item p-2" eventKey="3" onClick={handleLogout}>Cerrar sesión</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        {/* Perfil se despliega solo si es una pantalla pequeña */}
        <div className="perfil-sm d-xs-block d-sm-block d-md-none d-lg-none">
          <Navbar expand="lg">
            <Nav className="justify-content-center">
              <Nav.Item className="text-center mt-1 mb-1"><img className="perfil-foto" src={(edificio.foto) ? process.env.STRAPI_URL + edificio.foto.url : '/img/perfil-default.png'} /></Nav.Item>
              <Nav.Item className="text-center mt-1 mb-1">{edificio.nombre}</Nav.Item>
              <Nav.Item className="text-center mt-1 mb-1" onClick={handleShow}>Perfil establecimiento</Nav.Item>
              {/* <Nav.Item className="text-center mt-1 mb-1" onClick={handleShowEst}>Cambiar establecimiento</Nav.Item> */}
              <Nav.Item className="text-center mt-1 mb-1" onClick={handleLogout}>Cerrar sesión</Nav.Item>
            </Nav>
          </Navbar>
        </div>

        <Modal key="modPerfil" show={show} onHide={handleClose} size="lg" centered>
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            <div className="row perfil-modal">
              <div className="col-sm-12 col-lg-4 text-center">
                <img className="perfil-foto" src={(edificio.foto) ? process.env.STRAPI_URL + edificio.foto.url : '/img/perfil-default.png'} />
              </div>
              <div className="col-sm-12 col-lg-8 pt-3">
                <h3 className="perfil-titulo">{edificio.nombre}</h3>
                <div className="perfil-items">
                  <div className="row perfil-item">
                    <p className="col-sm-12 col-md-5">Dirección</p>
                    <p className="col-sm-12 col-md-7">{edificio.direccion}</p>
                  </div>
                  <div className="row perfil-item">
                    <p className="col-sm-12 col-md-5">Jefe de Operaciones</p>
                    <p className="col-sm-12 col-md-7">{edificio.jefe_operacional}</p>
                  </div>
                  <div className="row perfil-item">
                    <p className="col-sm-12 col-md-5">Último retiro realizado</p>
                    <p className="col-sm-12 col-md-7">{edificio.ultimo_retiro}</p>
                  </div>
                </div>
                {(edificiosUsuario && edificiosUsuario.length > 1)?
                <div className="pt-2">
                  <Dropdown>
                    <Dropdown.Toggle variant="seccondary" id="dropdown-edificio" className="btn-default btn-lg btn-block">
                      Cambiar de edificio
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="btn-lg btn-block">
                      {
                      edificiosUsuario.map(edificio =>(
                        <Dropdown.Item key={"ed_"+edificio.id} onClick={() => (cambiarEdificio(edificio))}>{edificio.nombre} - {edificio.codigo}</Dropdown.Item>
                        )
                      )
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                :
                ""
                }
              </div>
            </div>
          </Modal.Body>
        </Modal>        

        <Modal key="modEstablecimiento" show={showEst} onHide={handleCloseEst} size="lg" centered>
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            <div className="row perfil-modal">
              <div className="col-sm-12 col-lg-4 text-center">
                <img className="perfil-foto" src={(edificio.foto) ? process.env.STRAPI_URL + edificio.foto.url : '/img/perfil-default.png'} />
              </div>
              <div className="col-sm-12 col-lg-8">
                <h3 className="perfil-titulo">{edificio.nombre}</h3>
                <div>
                <Dropdown>
                  <Dropdown.Toggle variant="seccondary" id="dropdown-edificio" className="btn-default btn-lg btn-block">
                    Cambiar de edificio
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="btn-lg btn-block">
                    {(edificiosUsuario)?
                    edificiosUsuario.map(edificio =>(
                      <Dropdown.Item key={"ed_"+edificio.id} onClick={() => (cambiarEdificio(edificio))}>{edificio.nombre} - {edificio.codigo}</Dropdown.Item>
                      )
                    )
                    :
                    ""
                    }
                  </Dropdown.Menu>
                </Dropdown>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
      :
      <>
      
      {/* Perfil se despliega solo si es una pantalla grande */}
        <Dropdown className="perfil d-none d-md-block">
          <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
            <div className="d-flex flex-row justify-content-end align-items-center">
              <i className="fas fa-times-circle text-danger"></i>              
              <div className="perfil-nombre">Sin edificios</div>
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu className="perfil-items" as={CustomMenu}>
            <Dropdown.ItemText className="perfil-text p-2">No existen edificios<br/> asociados</Dropdown.ItemText>
            <Dropdown.Item className="perfil-item p-2" eventKey="2" onClick={handleLogout}>Cerrar sesión</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        {/* Perfil se despliega solo si es una pantalla pequeña */}
        <div className="perfil-sm d-xs-block d-sm-block d-md-none d-lg-none">
          <Navbar expand="lg">
            <Nav className="justify-content-center">
              {/* <Nav.Text className="text-center mt-1 mb-1">No existen edificios<br/> asociados</Nav.Text> */}
              {/* <Navbar.Brand>No existen edificios<br/> asociados</Navbar.Brand> */}
              <Nav.Item className="text-center mt-1 mb-1" onClick={handleLogout}>Cerrar sesión</Nav.Item>
            </Nav>
          </Navbar>
        </div>
      </>
      }
    </>
  );
}

export default Perfil;