import React, { useEffect, useState } from "react"
import { connect } from 'react-redux'
import { useSelector } from 'react-redux'
import { Link } from "gatsby"
import { Navbar, Nav, NavDropdown} from 'react-bootstrap'
import { generaUltimos13Meses } from '../store/actions/retiros'
import { fechaAtexto, textoAFecha } from '../utils/momentUtils'

const Filtros = props => {
  const periodo = useSelector(state => (state)?state.app.periodo:{})

  const [listaMesesFiltro, setListaMesesFiltro] = useState(null)
  const [certificadosSeleccionado, setCertificadosSeleccionado] = useState(false)

  const urlBase = '/app/dashboard/'

  useEffect(() => {
    setListaMesesFiltro( generaUltimos13Meses( fechaAtexto() ) )
    setCertificadosSeleccionado( (props.locationPath)?props.locationPath.search("certificados")>0:false )
  }, [props.locationPath]);

  // useEffect(() => {
  //   console.debug("y?", certificadosSeleccionado)
  // }, [locationPath]);

  return (
    <>
    {/* Filtros se despliega solo si es una pantalla grande */}
    <div className="container filtros d-none d-md-block">
      <div className="row">
        <div className="col-9">
          <div className="row pl-4">
            <div className="text-uppercase"><label className="align-middle">Periodo:</label></div>
            <NavDropdown title={periodo && periodo.periodoTexto?periodo.periodoTexto:"Meses de servicio"} id="meses">
              {(listaMesesFiltro)?
              listaMesesFiltro.map(meses =>(
                <NavDropdown.Item key={meses.periodo} as={Link} to={urlBase + meses.periodo} className={`upper-first ${meses.periodo === periodo.periodo?"filtro-sel":"filtro"}`}>{textoAFecha(meses.periodo, "MMYYYY").format("MMMM YYYY")}</NavDropdown.Item>
              ) )
              :
              ""
              }
            </NavDropdown>
          </div>
        </div>
        <div className="col-3">
          <div className="text-center">
            <Link className={`btn btn-revisa ${(certificadosSeleccionado?"selected":"")}`} to="/app/certificados">Centro de Certificados</Link>
          </div>
          
        </div>
      </div>
    </div>

    {/* Filtros se despliega solo si es una pantalla pequeña */}
    <div className="filtros-sm d-xs-block d-sm-block d-md-none d-lg-none">
      <Navbar expand="sm">
        <Nav className="mr-auto">
          <NavDropdown title="Meses de servicio" id="basic-nav-dropdown" onSelect={() => props.setExpanded(false)}>
              {(listaMesesFiltro)?
              listaMesesFiltro.map(meses =>(
                <NavDropdown.Item key={meses.periodo} as={Link} to={urlBase + meses.periodo} className={`upper-first ${meses.periodo === periodo.periodo?"filtro-sel":"filtro"}`}>{textoAFecha(meses.periodo, "MMYYYY").format("MMMM YYYY")}</NavDropdown.Item>
              ) )
              :
              ""
              }
          </NavDropdown>
          <Nav.Link as={Link} to="/app/certificados" className="text-center" onClick={() => props.setExpanded(false)}>Centro de Certificados</Nav.Link>
        </Nav>
      </Navbar>
    </div>
    </>
  )
}

// export default Filtros
export default connect (null)(Filtros)