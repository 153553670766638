import React from 'react';

const Footer = props => {
  return (
    <section className="footer">
      <footer className="d-flex flex-wrap justify-content-center w-100 pt-0 pb-4">
        {/* imagen footer color negro */}
        <div className="certificado col-sm-12 p-2 text-center d-xs-block d-sm-block d-md-none d-lg-none">
          <img className="pt-1 pl-3 pr-3" alt="Empresa B" src="/img/certificada-w.png" />
        </div>
        <div className="footer d-flex flex-wrap w-100 p-2 pt-4">
          {/* logo gestion b */}
          <div className="col-sm-12 col-md-3 col-xl-3 p-2 text-left d-none d-md-block">
            <div className="d-flex h-100 align-items-center">
              <img className="footer-logo pr-5" alt="Logo Gestión B, Blanco" src="/img/logo-gestionb-w.png" />
            </div>
          </div>
          <div className="col-sm-12 col-md-4 p-1 pl-2 text-left d-none d-md-block">
            {/* <h4>800 000 000</h4> */}
            <h6>Horario de atención</h6>
            <span>lun-vier 10:00 - 18:00 / sab 10:00 - 14:30</span>
          </div>
          <div className="col-sm-12 col-md-4 p-1 pl-2 text-left d-none d-md-block">
            <h4 className="mt-4">contacto@gestionb.cl</h4>
            {/* <h4>contacto@gestionb.cl</h4> */}
            {/* <h6>Horario de atención</h6>
            <span>lun-vier 10:00 - 18:00 / sab 10:00 - 14:30</span> */}
          </div>
          <div className="col-sm-12 col-md-3 col-xl-3 p-2 text-center d-xs-block d-sm-block d-md-none d-lg-none">
            <img className="footer-logo" alt="Logo Gestión B, Blanco" src="/img/logo-gestionb-w.png" />
          </div>
          <div className="col-sm-12 col-md-4 p-2 text-center d-xs-block d-sm-block d-md-none d-lg-none">
            {/* <h4>800 000 000</h4>
            <h6>Horario de atención</h6>
            <span>lun-vier 10:00 - 18:00 / sab 10:00 - 14:30</span> */}
          </div>
          <div className="col-sm-12 col-md-4 p-2 text-center d-xs-block d-sm-block d-md-none d-lg-none">
            <h4>contacto@gestionb.cl</h4>
            {/* <h6>Horario de atención</h6>
            <span>lun-vier 10:00 - 18:00 / sab 10:00 - 14:30</span> */}
          </div>
        </div>
      </footer>
    </section>
  );
}

export default Footer;