import React from 'react';
import { Helmet } from 'react-helmet'

import Header from '../components/Header'
import Footer from '../components/Footer'
import 'bootstrap/dist/css/bootstrap.min.css';

const DefaultLayout = ({children, title, locationPath, isAuthenticated = false}) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="Descripción" />
        <meta name="author" content="CaWot" />
      </Helmet>
      {/* <section className="container"> */}
        <Header locationPath={locationPath} isAuthenticated={isAuthenticated} />

        <section className="container">
          {children}
        </section>

        <Footer />
      {/* </section> */}
    </>
  );
}

export default DefaultLayout;